import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/zeit/56c857c7/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    {
      /*
      ## ImageComponent
      <ImageComponent caption="Don’t use complex future tenses when simple ones will suffice. Don’t use complex future tenses when simple ones will suffice. Don’t use complex future tenses when simple ones will suffice.">
      ![blossom](../../images/blossom.jpg)
      </ImageComponent>
      <Row>
       <Column colLg={4}>
      <ImageComponent caption="Don’t use complex future tenses when simple ones will suffice. Don’t use complex future tenses when simple ones will suffice. Don’t use complex future tenses when simple ones will suffice.">
      ![blossom](../../images/blossom.jpg)
      </ImageComponent>
       </Column>
      </Row>
      <Row>
       <Column colLg={6}>
      <ImageComponent caption="Don’t use complex future tenses when simple ones will suffice. Don’t use complex future tenses when simple ones will suffice. Don’t use complex future tenses when simple ones will suffice.">
      ![blossom](../../images/blossom.jpg)
      </ImageComponent>
       </Column>
      </Row>
      <Row>
       <Column colLg={8}>
      <ImageComponent caption="Don’t use complex future tenses when simple ones will suffice. Don’t use complex future tenses when simple ones will suffice. Don’t use complex future tenses when simple ones will suffice.">
      ![blossom](../../images/blossom.jpg)
      </ImageComponent>
       </Column>
      </Row>
      ### Zoom
      Zoom styles are in progress/TBD
      <ImageComponent zoom caption="Don’t use complex future tenses when simple ones will suffice. Don’t use complex future tenses when simple ones will suffice. Don’t use complex future tenses when simple ones will suffice.">
      ![blossom](../../images/blossom.jpg)
      </ImageComponent>
      */
    }

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      